import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import { ClothingDataDB, CreditingType } from 'models'
import { Link } from 'react-router-dom'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useState } from 'react'

interface UploadCardParams {
  clothingData: ClothingDataDB
  link?: string
}

const UploadCard = ({ clothingData, link }: UploadCardParams) => {
  const [isCopying, setIsCopying] = useState(false)
  const handleCopyEmail = async () => {
    setIsCopying(true)
    await navigator.clipboard.writeText(clothingData.customer_email)
    setTimeout(() => setIsCopying(false), 1000)
  }

  const Content = () => (
    <CardContent>
      <Typography variant="body1">
        Customer name: {clothingData.customer_first_name}
      </Typography>
      <Typography variant="body1">
        Email: {clothingData.customer_email}
        {!link && (
          <Tooltip
            title="Copied email"
            arrow
            placement="right"
            open={isCopying}
          >
            <IconButton
              aria-label="copy"
              size="small"
              sx={{ marginLeft: 0.5 }}
              onClick={handleCopyEmail}
              disabled={isCopying}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      <Typography variant="body1">
        Collection date:{' '}
        {clothingData.collection_date.toLocaleDateString(undefined, {
          dateStyle: 'medium',
        })}
      </Typography>
      <Typography variant="body1">
        Type: {clothingData.crediting_type}
      </Typography>
      <Typography variant="body1">
        Total no. of items:{' '}
        {clothingData.clothes.length + clothingData.rejected_items.length}
      </Typography>
      <Typography variant="body1">
        No. of accepted items: {clothingData.clothes.length}
      </Typography>
      <Typography variant="body1">
        No. of rejected items: {clothingData.rejected_items.length}
      </Typography>
      <Typography variant="body1">
        Uploaded by: {clothingData.staff.join(', ')}
      </Typography>
      <Typography variant="body1">
        Upload started:{' '}
        {clothingData.timestamp_created.toLocaleDateString(undefined, {
          dateStyle: 'medium',
        })}{' '}
        {clothingData.timestamp_created.toLocaleTimeString()}
      </Typography>
      {clothingData.timestamp_submitted && (
        <Typography variant="body1">
          Upload submitted:{' '}
          {clothingData.timestamp_submitted.toLocaleDateString(undefined, {
            dateStyle: 'medium',
          })}{' '}
          {clothingData.timestamp_submitted.toLocaleTimeString()}
        </Typography>
      )}
      {clothingData.crediting_type === CreditingType.TRADE_IN && (
        <Typography variant="body1">
          Total crediting amount: ${clothingData.total_crediting_amount}
        </Typography>
      )}
    </CardContent>
  )

  return (
    <Card elevation={3} sx={{ backgroundColor: '#e1f5fe' }}>
      {link !== undefined ? (
        <CardActionArea component={Link} to={link}>
          <Content />
        </CardActionArea>
      ) : (
        <Content />
      )}
    </Card>
  )
}

export default UploadCard
