import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { get_submitted_vintage_books } from 'api/clothes_service'
import { VintageBook } from 'models'
import { useEffect, useState } from 'react'

const formatDatetime = (datetimeString: string) => {
  const dt = new Date(datetimeString)
  const d = dt.toLocaleDateString(undefined, {
    dateStyle: 'medium',
  })
  const t = dt.toLocaleTimeString()
  return `${d} ${t}`
}

const Book = ({ book }: { book: VintageBook }) => {
  return (
    <Card elevation={3} sx={{ backgroundColor: '#e1f5fe' }}>
      <CardContent>
        <Stack mb={1}>
          <Typography>
            <strong>{book.info.title}</strong>
          </Typography>
          {book.is_rejected && (
            <Typography fontStyle="italic">Rejected book</Typography>
          )}
        </Stack>

        <Stack mb={1}>
          {book.sku && <Typography>SKU: {book.sku}</Typography>}
          <Typography>Uploaded by: {book.staff_name}</Typography>
          <Typography>
            Started: {formatDatetime(book.timestamp_created)}
          </Typography>
          <Typography>
            Submitted: {formatDatetime(book.timestamp_submitted)}
          </Typography>
        </Stack>

        <Stack mb={1}>
          {book.info.isbn && <Typography>ISBN: {book.info.isbn}</Typography>}
          {book.info.publisher && (
            <Typography>Publisher: {book.info.publisher}</Typography>
          )}
          {book.info.year && (
            <Typography>Publication year: {book.info.year}</Typography>
          )}
          {book.info.edition && (
            <Typography>Edition: {book.info.edition}</Typography>
          )}
          {book.info.series && (
            <Typography>Series: {book.info.series}</Typography>
          )}
          {book.info.primary_genre && (
            <Typography>Primary genre: {book.info.primary_genre}</Typography>
          )}
          {book.info.secondary_genre && (
            <Typography>
              Secondary genre: {book.info.secondary_genre}
            </Typography>
          )}
          {book.info.format && (
            <Typography>Format: {book.info.format}</Typography>
          )}
          {book.info.authors.length > 0 && (
            <Typography>Authors: {book.info.authors.join(' | ')}</Typography>
          )}
          {book.info.editors.length > 0 && (
            <Typography>Editors: {book.info.editors.join(' | ')}</Typography>
          )}
          {book.info.translators.length > 0 && (
            <Typography>
              Translators: {book.info.translators.join(' | ')}
            </Typography>
          )}
          {book.info.illustrators.length > 0 && (
            <Typography>
              Illustrators: {book.info.illustrators.join(' | ')}
            </Typography>
          )}
        </Stack>

        <Stack mb={1}>
          <Typography>Condition grade: {book.condition}</Typography>
          {book.condition_details && (
            <>
              <Typography>
                Ex-library:{' '}
                {book.condition_details.is_ex_library ? 'Yes' : 'No'}
              </Typography>
              <Typography>
                Binding condition: {book.condition_details.binding_condition}
              </Typography>
              <Typography>
                Front cover foxing: {book.condition_details.front_cover_foxing}
              </Typography>
              <Typography>
                Front cover wear & tear:{' '}
                {book.condition_details.front_cover_wear_and_tear.join(', ')}
              </Typography>
              <Typography>
                Front cover annotations:{' '}
                {book.condition_details.front_cover_annotations ?? '-'}
              </Typography>
              <Typography>
                Front cover gilt: {book.condition_details.front_cover_gilt}
              </Typography>
              <Typography>
                Back cover foxing: {book.condition_details.back_cover_foxing}
              </Typography>
              <Typography>
                Back cover wear & tear:{' '}
                {book.condition_details.back_cover_wear_and_tear.join(', ')}
              </Typography>
              <Typography>
                Back cover annotations:{' '}
                {book.condition_details.back_cover_annotations ?? '-'}
              </Typography>
              <Typography>
                Spine foxing: {book.condition_details.spine_foxing}
              </Typography>
              <Typography>
                Spine tear(s): {book.condition_details.spine_tears}
              </Typography>
              <Typography>
                Spine fading: {book.condition_details.spine_fading}
              </Typography>
              <Typography>
                Spine gilt: {book.condition_details.spine_gilt}
              </Typography>
              <Typography>
                Top edge tanning: {book.condition_details.top_edge_tanning}
              </Typography>
              <Typography>
                Top edge foxing: {book.condition_details.top_edge_foxing}
              </Typography>
              <Typography>
                Fore edge tanning: {book.condition_details.fore_edge_tanning}
              </Typography>
              <Typography>
                Fore edge foxing: {book.condition_details.fore_edge_foxing}
              </Typography>
              <Typography>
                Bottom edge tanning:{' '}
                {book.condition_details.bottom_edge_tanning}
              </Typography>
              <Typography>
                Bottom edge foxing: {book.condition_details.bottom_edge_foxing}
              </Typography>
              <Typography>
                Bookplate: {book.condition_details.has_bookplate ? 'Yes' : 'No'}
              </Typography>
              <Typography>
                Inside cover foxing:{' '}
                {book.condition_details.inside_cover_foxing}
              </Typography>
              <Typography>
                Inside cover annotations:{' '}
                {book.condition_details.inside_cover_annotations ?? '-'}
              </Typography>
              <Typography>
                Page foxing: {book.condition_details.page_foxing}{' '}
                {book.condition_details.page_foxing_frequency.toLowerCase()}
              </Typography>
              <Typography>
                Page annotations: {book.condition_details.page_annotations}{' '}
                {book.condition_details.page_annotation_frequency.toLowerCase()}
              </Typography>
              <Typography>
                Page margin tanning:{' '}
                {book.condition_details.page_margin_tanning}
              </Typography>
              <Typography>
                Page tear(s): {book.condition_details.page_tears ?? '-'}
              </Typography>
              <Typography>
                Inserts: {book.condition_details.inserts ? 'Yes' : 'No'}
              </Typography>
              {book.condition_details.inserts && (
                <>
                  <Typography>
                    Inserts condition:{' '}
                    {book.condition_details.inserts.condition}
                  </Typography>
                  <Typography>
                    Inserts foxing: {book.condition_details.inserts.foxing}
                  </Typography>
                  <Typography>
                    Inserts tear(s):{' '}
                    {book.condition_details.inserts.tears ?? '-'}
                  </Typography>
                </>
              )}
              <Typography>
                Others: {book.condition_details.others ?? '-'}
              </Typography>
            </>
          )}
        </Stack>

        <Stack>
          <Typography>Sale price: ${book.sale_price}</Typography>
          <Typography>
            Original photos:{' '}
            <a
              href={book.display_photo_urls[0]}
              target="_blank"
              rel="noreferrer"
            >
              front
            </a>
            ,{' '}
            <a
              href={book.display_photo_urls[1]}
              target="_blank"
              rel="noreferrer"
            >
              back
            </a>
            ,{' '}
            <a
              href={book.display_photo_urls[2]}
              target="_blank"
              rel="noreferrer"
            >
              page 1
            </a>
            ,{' '}
            <a
              href={book.display_photo_urls[3]}
              target="_blank"
              rel="noreferrer"
            >
              page 2
            </a>
            {book.defect_photo_url && (
              <>
                ,{' '}
                <a
                  href={book.defect_photo_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  defect
                </a>
              </>
            )}
            {book.video_url && (
              <>
                ,{' '}
                <a href={book.video_url} target="_blank" rel="noreferrer">
                  video
                </a>
              </>
            )}
          </Typography>
          {book.is_uploaded && (
            <Typography>
              Processed photos:{' '}
              <a
                href={`${book.display_photo_urls[0].replace(
                  '.jpg',
                  ''
                )}_processed.png`}
                target="_blank"
                rel="noreferrer"
              >
                front
              </a>
              ,{' '}
              <a
                href={`${book.display_photo_urls[1].replace(
                  '.jpg',
                  ''
                )}_processed.png`}
                target="_blank"
                rel="noreferrer"
              >
                back
              </a>
              ,{' '}
              <a
                href={`${book.display_photo_urls[2].replace(
                  '.jpg',
                  ''
                )}_processed.png`}
                target="_blank"
                rel="noreferrer"
              >
                page 1
              </a>
              ,{' '}
              <a
                href={`${book.display_photo_urls[3].replace(
                  '.jpg',
                  ''
                )}_processed.png`}
                target="_blank"
                rel="noreferrer"
              >
                page 2
              </a>
              {book.defect_photo_url && (
                <>
                  ,{' '}
                  <a
                    href={`${book.defect_photo_url.replace(
                      '.jpg',
                      ''
                    )}_processed.png`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    defect
                  </a>
                </>
              )}
            </Typography>
          )}
          {book.product_id && (
            <Typography>
              <a
                href={`https://admin.shopify.com/store/thryft-singapore/products/${book.product_id}`}
                target="_blank"
                rel="noreferrer"
              >
                Shopify link
              </a>
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

const VintageBooks = () => {
  const [books, setBooks] = useState<VintageBook[] | undefined>(undefined)

  useEffect(() => {
    const getVintageBooks = async () => {
      const books = await get_submitted_vintage_books()
      setBooks(books)
    }
    getVintageBooks()
  }, [])

  if (books === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={2}>
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography>Loading data...</Typography>
        </Stack>
      </Box>
    )
  }
  return (
    <Stack rowGap={3}>
      <Typography variant="h3">Vintage Books</Typography>
      {books.map((book, i) => (
        <Book book={book} key={i} />
      ))}
    </Stack>
  )
}

export default VintageBooks
